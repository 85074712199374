import React from "react";
import { motion } from "framer-motion";
import "./Features.css";

import ComputerIcon from '@mui/icons-material/Computer';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DiamondIcon from '@mui/icons-material/Diamond';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

const Features = () => {
	return (
		<div className="Features-section" id="features">
            <div className="Features-left">
                <div className="Features-title">
                    <h1>¿Por qué elegir Betterdrive?</h1>
                </div>
                <motion.div 
                    initial={{ x: '-100' }}
                    whileInView={{ x: 0 }}
                    transition={{type: 'spring', duration:1.5, bounce: 0.3}}
                    viewport={{ once: true }}
                    className="Features-wrapper">
                    <div className="Feature-object">
                        <div className="Feature-icon-section">
                            <ComputerIcon fontSize="large" className="circle-icon"></ComputerIcon>
                        </div>
                        <div className="Feature-text-section">
                            <h4>Proceso 100% digital</h4>
                            <p>Date de alta en sólo minutos. Sin recurrir a una inspección. Hazla 100% remota.</p>
                        </div>
                    </div>
                    <div className="Feature-object">
                        <div className="Feature-icon-section">
                            <AttachMoneyIcon fontSize="large" className="circle-icon"></AttachMoneyIcon>
                        </div>
                        <div className="Feature-text-section">
                            <h4>Más barato imposible</h4>
                            <p>Desarrollamos productos con precios que se ajustan a la realidad de cada bolsillo.</p>
                        </div>
                    </div>
                    <div className="Feature-object">
                        <div className="Feature-icon-section">
                            <CurrencyExchangeIcon fontSize="large" className="circle-icon"></CurrencyExchangeIcon>
                        </div>
                        <div className="Feature-text-section">
                            <h4>Cashback de verdad</h4>
                            <p>Premiamos tu buena conducción, con hasta el 20% de lo que pagas anualmente.</p>
                        </div>
                    </div>
                    <div className="Feature-object">
                        <div className="Feature-icon-section">
                            <DiamondIcon fontSize="large" className="circle-icon"></DiamondIcon>
                        </div>
                        <div className="Feature-text-section">
                            <h4>Respuesta ágil</h4>
                            <p>Tenemos un equipo de soporte web o telefónico 24/7 para ti.</p>
                        </div>
                    </div>
                    <div className="Feature-object">
                        <div className="Feature-icon-section">
                            <ContentPasteSearchIcon fontSize="large" className="circle-icon"></ContentPasteSearchIcon>
                        </div>
                        <div className="Feature-text-section">
                            <h4>Sin letra chica</h4>
                            <p>Sin sorpresas, ni condiciones abusivas.</p>
                        </div>
                    </div>
                    <div className="Feature-object">
                        <div className="Feature-icon-section">
                            <DiamondIcon fontSize="large" className="circle-icon"></DiamondIcon>
                        </div>
                        <div className="Feature-text-section">
                            <h4>Ágilidad</h4>
                            <p>Pagamos tu reparación en un plázo máximo de 10 días hábiles.</p>
                        </div>
                    </div>
                </motion.div>
            </div>
            <motion.div 
                initial={{ x: '100' }}
                whileInView={{ x: 0 }}
                transition={{type: 'spring', duration:1.5, bounce: 0.3}}
                viewport={{ once: true }}
                className="Features-right">
                <img src={require("../assets/images/betterdrive-mobile1.png")} alt="feature-img"></img>
            </motion.div>
		</div>
	);
};

export default Features;
