import React from "react";
import "./Footer.css";

const props = {
	socialNetwork: [
		{
			name: "Facebook",
			logoSrc: require("../assets/icons/fb_icon.png"),
			link: "https://www.facebook.com/betterdrive.io",
		},
		{
			name: "Instagram",
			logoSrc: require("../assets/icons/instagram_icon.png"),
			link: "https://www.instagram.com/betterdrive.io/",
		},
		/*{
			name: "Linkedin",
			logoSrc: require("../assets/icons/linkedin_icon.png"),
			link: "http://www.linkedin.com",
		},
		{
			name: "Twitter",
			logoSrc: require("../assets/icons/twitter_icon.png"),
			link: "http://www.twitter.com",
		},
		{
			name: "Youtube",
			logoSrc: require("../assets/icons/youtube_icon.png"),
			link: "http://www.youtube.com",
		},*/
	],
	textBottom: {
		year: 2022,
		company: "Betterdrive",
	},
};

const Footer = () => {
	return (
		<div className="footer-container">
			<footer>
				<section>
					{props.socialNetwork.map(network => (
						<a
							href={network.link}
							target="_blank"
							key={network.name}
							rel="noreferrer"
						>
							<img src={network.logoSrc} alt=""></img>
						</a>
					))}
					</section>
				<p>
					©{props.textBottom.year} {props.textBottom.company}. All rights
					reserved.
				</p>
			</footer>
		</div>
	);
};

export default Footer;
