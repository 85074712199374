import React, { useState } from "react";
import { formValidation, fieldValidation } from "../helpers/formValidation";
import QuotationForm from "./QuotationForm";
import Thanks from "./Thanks";
import "./Quotation.css";

const Quotation = props => {
	const [quotationDetails, setQuotationDetails] = useState({
		name: "",
		phone: "",
		email: "",
		message: props.planName ? "Plan: " + props.planName : "Plan:",
	});
	const [isSent, setIsSent] = useState(false);

	const handleChange = event => {
		if (fieldValidation(event)) {
			setQuotationDetails({
				...quotationDetails,
				[event.target.name]: event.target.value,
			});
		}
	};
	
	const handleSubmit = event => {
		event.preventDefault();
		fetch("https://hooks.zapier.com/hooks/catch/11817844/bwyyone/", {
			method: "POST",
			body: JSON.stringify(quotationDetails),
		})
			.then(res => res.json())
			.then(data => {
				console.log(data.status);
				setQuotationDetails({
					name: "",
					phone: "",
					email: "",
					message: props.planName ? "Plan: " + props.planName : "Plan:",
				});
				setIsSent(true);
			});
	};

	return (
		<div className="quotation-container">
			<img
				src={require("../assets/icons/close_icon.png")}
				alt=""
				onClick={props.handleClose}
			/>
			{!isSent ? (
				<QuotationForm
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					quotationDetails={quotationDetails}
					formValidation={formValidation}
					headerText={props.headerText}
				/>
			) : (
				<Thanks />
			)}
		</div>
	);
};

export default Quotation;
