import React from "react";
import Modal from "../components/Modal"
import "./Subscribe.css";

const Subscribe = () => {
	return (
		<div className="subscribe-section">
                <h1>¿Quieres saber más?</h1>
                <p>Si te interesa saber como conducir mejor te puede traer diferentes beneficios, no dudes en contactarnos.</p>
                <Modal buttonText={"Comenzar"} form={"quotation"}/>
        </div>
	);
};

export default Subscribe;
