import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Quotation from "./Quotation";
import Contact from "./Contact";
import "./Modal.css";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "#ffffff",
	border: "0",
	borderRadius: "20px",
	boxShadow: 24,
	p: 4,
	padding: "0",
};

const buttonStyle = {
	backgroundColor: "#2a0449",
	"&:hover": {
		backgroundColor: "#2a0449",
	},
	color: "#ffffff",
	padding: "3% 10%",
	borderRadius: "20px",
	fontSize: "1.1vw",
	cursor: "pointer",
	"@media (max-width: 950px)": {
		fontSize: "2vw",
		margin: "0",
		justifyContent: "center",
	},
	"@media (max-width: 650px)": {
		fontSize: "4vw",
	},
	"@media (max-width: 500px)": {
		fontSize: "4.5vw",
	},
};

const navbarStyle = {
	textAlign: "center",
	color: "#ffffff",
	fontSize: "1.4vw",
	fontWeight: "500",
	cursor: "pointer",
	textTransform: "capitalize",
	padding: "0",
	marginBottom: "-2px",
	height: "fit-content",
	"@media (max-width: 950px)": {
		fontSize: "30px",
		color: "#2a0449",
	},
};

const BasicModal = props => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<div
			className={
				props.form === "quotation"
					? "modal-container-button"
					: "modal-container-navbar"
			}
		>
			<Button
				sx={props.form === "quotation" ? buttonStyle : navbarStyle}
				onClick={handleOpen}
			>
				{props.buttonText}
			</Button>
			<Modal open={open} onClose={handleClose}>
				<Box sx={modalStyle}>
					{props.form === "quotation" ? (
						<Quotation
							handleClose={handleClose}
							headerText={props.buttonText}
							planName={props.planName}
						/>
					) : (
						<Contact handleClose={handleClose} headerText={props.buttonText} />
					)}
				</Box>
			</Modal>
		</div>
	);
};

export default BasicModal;
