import React from "react";

import HomeTitle from "../components/HomeTitle";
import Plans from "../components/Plans";
import Features from "../components/Features";
import Facts from "../components/Facts";
import Subscribe from "../components/Subscribe";
import HowItWorks from "../components/HowItWorks";

import "./Home.css";

const Home = () => {
	return (
		<div className="home-container">
			<div className="home-header" id="header">
				<HomeTitle />
			</div>
			<div className="home-facts">
				<Facts/>
			</div>
			<div className="home-howItWorks">
				<HowItWorks/>
			</div>
			<div className="home-subscribe">
				<Subscribe/>
			</div>
			<div className="home-features">
				<Features/>
			</div>
			<Plans />
		</div>
	);
};

export default Home;
