import React from "react";
import PlanCard from "./PlanCard";
import data from "../assets/data/data.json";
import "./Plans.css";

const Plans = () => {
	const plansData = data.plans;
	return (
		<div className="plans-container" id="plans">
			<h1>Precios imbatibles</h1>
			<p>
			¡Tenemos seguros para todos! Revisa nuestros planes y evalúa la mejor alternativa para ti. Aquí no discriminamos, ofrecemos los seguros más accesibles del mercado
			</p>
			<section>
				{plansData.map(plan => (
					<PlanCard planDetails={plan} key={plan.name} />
				))}
			</section>
		</div>
	);
};

export default Plans;
