import React from "react";
import "./QuotationForm.css";

const QuotationForm = props => {
	return (
		<div className="quotationForm-container">
			<h1>{props.headerText}</h1>
			<form onSubmit={props.handleSubmit}>
				<fieldset>
					<label htmlFor="name">Nombre</label>
					<input
						type="text"
						id="name"
						name="name"
						onChange={props.handleChange}
						value={props.quotationDetails.name}
					/>
				</fieldset>
				<fieldset>
					<label htmlFor="email">Email</label>
					<input
						type="text"
						id="email"
						name="email"
						onChange={props.handleChange}
						value={props.quotationDetails.email}
					/>
				</fieldset>
				<fieldset>
					<label htmlFor="phone">Telefono de contacto</label>
					<input
						type="text"
						id="phone"
						name="phone"
						onChange={props.handleChange}
						value={props.quotationDetails.phone}
					/>
				</fieldset>
				<button
					type="submit"
					disabled={
						!props.formValidation(props.quotationDetails)
					}
				>
					Enviar
				</button>
			</form>
		</div>
	);
};

export default QuotationForm;
