import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import AboutUs from "./views/AboutUs";

function App() {
	return (
		<div className="App">
			<NavBar />
			<Routes>
				<Route path="/" element={<Home />}></Route>
				<Route path="/about" element={<AboutUs />}></Route>
			</Routes>
			<Footer />
		</div>
	);
}

export default App;
