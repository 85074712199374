import React from "react";
import { motion } from "framer-motion";
import "./Facts.css";

const Facts = () => {
	return (
		<div className="facts-section-container" id="facts">
			<h4>
				Recompensamos tus buenos hábitos de conducción con premios, descuentos e
				incluso un reembolso del 20% en tu seguro
			</h4>
			<section>
				<div>
					<img src={require("../assets/icons/laptop_icon.png")} alt="" />
				</div>
				<p>100% Digital</p>
			</section>
			<section>
				<div>
					<img src={require("../assets/icons/smartphone_icon.png")} alt="" />
				</div>
				<p>Todo en una aplicación</p>
			</section>
			<section>
				<div>
					<img src={require("../assets/icons/calendar_icon.png")} alt="" />
				</div>
				<p>Rápido y facil, 24/7</p>
			</section>
			<section>
				<motion.h3
					initial={{ x: "-150" }}
					whileInView={{ x: 0 }}
					transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
					viewport={{ once: true }}
				>
					¡Ingresa, contrata, asegúrate y reclama tu cobertura!
				</motion.h3>
				<motion.img
					initial={{ x: "150" }}
					whileInView={{ x: 0 }}
					transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
					viewport={{ once: true }}
					src={require("../assets/images/betterdrive-mobile3.png")}
					alt=""
				/>
			</section>
		</div>
	);
};

export default Facts;
