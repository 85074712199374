import React from "react";
import { motion } from "framer-motion";
import Modal from "./Modal";
import "./HomeTitle.css";

const HomeTitle = () => {
	return (
		<div className="homeTitle-container">
			<motion.div 
				className="homeTitle-textContainer"
				initial={{ x: '-150' }}
				whileInView={{ x: 0 }}
				transition={{type: 'spring', duration:1.5, bounce: 0.3}}
				viewport={{ once: true }}>
				<h1>¡Conduce mejor, gana puntos y ahorra en tu seguro!</h1>
				<p>
				<label>Más</label> que seguros una forma de vivir mejor <br/>
				<label>+</label>simple. <label>+</label>rápido. <label>+</label>barato.
				</p>
				<Modal buttonText="Quiero que me contacten" form={"quotation"} />
			</motion.div>
			<motion.div 
				className="homeTitle-imgContainer"
				initial={{ x: '150' }}
				whileInView={{ x: 0 }}
				transition={{type: 'spring', duration:1.5, bounce: 0.3}}
				viewport={{ once: true }}>
				<img src={require('../assets/images/betterdrive-mobile2.png')} alt="cellphone-home"></img>
			</motion.div>
		</div>
	);
};

export default HomeTitle;
