import React from "react";
import "./ContactForm.css";

const ContactForm = props => {
	return (
		<div className="contactForm-container">
			<form onSubmit={props.handleSubmit}>
				<fieldset>
					<label htmlFor="name">Nombre</label>
					<input
						type="text"
						id="name"
						name="name"
						onChange={props.handleChange}
						value={props.contactDetails.name}
					/>
				</fieldset>
				<fieldset>
					<label htmlFor="phone">Telefono</label>
					<input
						type="text"
						id="phone"
						name="phone"
						onChange={props.handleChange}
						value={props.contactDetails.phone}
					/>
				</fieldset>
				<fieldset>
					<label htmlFor="email">Correo</label>
					<input
						type="text"
						id="email"
						name="email"
						onChange={props.handleChange}
						value={props.contactDetails.email}
					/>
				</fieldset>
				<fieldset>
					<label htmlFor="message">Mensaje</label>
					<textarea
						type="text"
						id="message"
						name="message"
						onChange={props.handleChange}
						value={props.contactDetails.message}
					/>
				</fieldset>
				<button
					type="submit"
					disabled={!props.formValidation(props.contactDetails)}
				>
					Enviar
				</button>
			</form>
		</div>
	);
};

export default ContactForm;
