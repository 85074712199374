import React from "react";
import "./HowItWorks.css"

const HowItWorks = () => {
	return (
		<div className="howItWorks-container" id="howItWorks">
			<section>
				<h1>¿Cómo funciona?</h1>
			</section>
			<section>
				<h4>Maneja</h4>
				<p>
                Maneja tus primeros kilómetros, nuestra tecnología nos permitirá conocer tus hábitos de manejo y te asignaremos un puntaje
				</p>
			</section>
			<section>
				<img src={require("../assets/images/drive.jpg")} alt="" />
			</section>
			<section>
				<img src={require("../assets/images/save.jpg")} alt="" />
			</section>
			<section>
				<h4>Ahorra</h4>
				<p>
                Elige el plan que más te acomode y paga el precio más barato del mercado. Ofrecemos la mejor experiencia, ajustandonos a la realidad de cada bolsillo.
				</p>
			</section>
			<section>
				<h4>Disfruta</h4>
				<p>
                Nuestro asistente de conduccion, te ayudará a mejorar tu forma de manejar. Podrás ganar premios, descuentos e incluso un reembolso del 20% en tu seguro.
				</p>
			</section>
			<section>
				<img src={require("../assets/images/enjoy.jpg")} alt="" />
			</section>
		</div>
	);
};

export default HowItWorks;
