import React, { useState } from "react";
import { formValidation, fieldValidation } from "../helpers/formValidation";
import ContactForm from "./ContactForm";
import Thanks from "./Thanks";
import "./Contact.css";

const Contact = props => {
	const [contactDetails, setContactDetails] = useState({
		name: "",
		phone: "",
		email: "",
		message: "",
	});
	const [isSent, setIsSent] = useState(false);

	const handleChange = event => {
		if (fieldValidation(event)) {
			setContactDetails({
				...contactDetails,
				[event.target.name]: event.target.value,
			});
		}
	};

	const handleSubmit = event => {
		event.preventDefault();
		fetch("https://hooks.zapier.com/hooks/catch/11817844/bwyyone/", {
			method: "POST",
			body: JSON.stringify(contactDetails),
		})
			.then(res => res.json())
			.then(data => {
				console.log(data.status);
				setContactDetails({
					name: "",
					phone: "",
					email: "",
					message: "",
				});
				setIsSent(true);
			});
	};

	return (
		<div className="contact-container">
			<img
				src={require("../assets/icons/close_icon.png")}
				alt=""
				onClick={props.handleClose}
			/>
			{!isSent ? (
				<ContactForm
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					contactDetails={contactDetails}
					formValidation={formValidation}
					headerText={props.headerText}
				/>
			) : (
				<Thanks />
			)}
		</div>
	);
};

export default Contact;
