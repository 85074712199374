import React from "react";
import { motion } from "framer-motion";
import Modal from "../components/Modal"
import "./PlanCard.css";

const PlanCard = props => {
	return (
		<motion.div
			initial={{ x: "-100" }}
			whileInView={{ x: 0 }}
			transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
			viewport={{ once: true }}
			className="planCard-container"
		>
			<h3>{props.planDetails.name}</h3>
			<h1>{props.planDetails.price}</h1>
			<p>{props.planDetails.details}</p>
			<Modal
				className="planCard-button"
				buttonText={"Lo quiero"}
				form={"quotation"}
				planName={props.planDetails.name}
			/>
			<p>{props.planDetails.info}</p>
		</motion.div>
	);
};

export default PlanCard;
