import React from "react";
import "./AboutUs.css";

const props = {
	aboutUs: [
		<h5>
			Los accidentes de tránsito son la mayor causa de mortalidad evitable en
			Latinoamérica. Causando más de 130.000 fallecidos y casi 6 millones de
			accidentes anualmente.
		</h5>,
		<h5>
			En Betterdrive estamos combatiendo los accidentes vehiculares en la región
			gracias a un modelo de incentivos que te premia por tus buenos hábitos de
			conducción. Así entre mejor conduces, más ahorras mes a mes.
		</h5>,
		<h5>
			Betterdrive es la primera aseguradora de autos de Latinoamérica que te
			entrega hasta un 25% de cashback anual por tus buenos hábitos de
			conducción. Con planes pensados para ti, sin letra chica.
		</h5>,
		<h5>Siéntete protegido, siéntete Betterdrive.</h5>,
	],
};

const AboutUs = () => {
	return (
		<div className="aboutUs-container">
			<section>{props.aboutUs.map(text => text)}</section>
		</div>
	);
};

export default AboutUs;
